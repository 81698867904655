@import '../../styles/common.scss';

.container {
  background: var(--bg-gray-0);
  text-align: center;
  background-repeat: no-repeat;
  background-size: 376px;
  padding-top: 51px;
  padding-bottom: 100px;
  background-position-x: 50%;
  background-position-y: 32px;

  @include sp {
    padding-bottom: 22px;
  }
}

.normalContainer {
  background: var(--bg-gray-0);
  padding: 64px;
  background-size: 431px 68px;
  background-repeat: no-repeat;
  background-position: 50% 56px;
  text-align: center;
  @include sp {
    padding: 32px 16px;
    background-position: 50% 24px;
  }
}

.normalHeading {
  text-align: center;
  color: var(--text-black);
  font-weight: bold;
  margin-top: 16px;
  letter-spacing: 0.24em;
  font-size: 20px;
  @include sp {
    font-size: 15px;
    margin-top: 20px;
  }
}

.note {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0.14rem;

  @include desktop {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
  @include sp {
    font-size: 9px;
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 40px;
  @include sp {
    margin-bottom: 24px;
  }
}

.subtitle {
  margin-bottom: 24px;
}

.subtitle > span {
  font-size: 12px;
}

.slider {
  margin-bottom: 64px !important;
}

.sliderImage {
  width: 120%;
}

.pcImageWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.pcImageItem {
  margin-right: 12px;
}

.presentContent {
  margin: 24px auto 0;

  @include sp {
    width: 90%;
  }
}

.stepArrow {
  margin: 0 auto;
}
