@import '../../styles/common.scss';

.container {
  background: #fff;
  padding-top: 28px;
  margin: 44px auto;
  max-width: 600px;
  @include sp {
    width: auto;
  }
}

.container li {
  display: flex;
  align-items: center;
  padding: 0 8vw 32px;
  @include sp {
    align-items: start;
    padding: 0 16px 32px;
  }
}

.listItem {
  flex: 1;
}

.listItem span {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 4px;
  letter-spacing: .24em;
}

.listItem p {
  text-align: left;
  font-size: 11px;
  line-height: 1.8;
}

.listImage {
  width: 98px;
  height: 90px;
  margin-right: 16px;
}

.container p {
  color: var(--text-gray-3);
}
