@import '../../styles/common.scss';

.container {
  background: #fff;
  margin: 0 auto 40px;
}

.applyContainer {
  margin: 48px auto 32px;
  text-align: center;
}

.applyContainer h2 {
  margin: 16px 0 40px;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: .18em;
}

.applyContainer img {
  display: block;
  margin: auto;
}
