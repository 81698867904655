@import '../../styles/common.scss';

.headerCountdown {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99999;
  top: 0;
  background-color: #fbf7f7;
  gap: 1vw;
  padding: 8px 0;
}

.countdownMessage {
  width: 425px;
  height: auto;

  @include sp {
    width: 37vw;
  }
}

.countdownTimer {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 0.3vw;

  @include sp {
    gap: 0.7vw;
  }
}

.countdownTextTime {
  background-color: #fff;
  color: #4c6072;
  width: 33px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  border-radius: 2px;

  @include sp {
    font-size: 4.2vw;
    width: 8.7vw;
  }
}

.countdownTextUnit {
  color: #4c6072;
  font-size: 14px;
  font-weight: bold;

  @include sp {
    font-size: 10px;
  }
}
